<template>
  <b-form v-on:submit.prevent="save">
    <b-alert class="mb-5" show="" variant="light"
      >You can modify group schedule after
      <strong>creating this group</strong></b-alert
    >

    <!-- begin: Name    -->
    <b-form-group :label="$t('GROUP.GENERAL.FORM.GROUPNAME')">
      <b-form-input
        v-model="form.name"
        :state="validateState('name')"
      ></b-form-input>
    </b-form-group>
    <!-- end: Name    -->

    <!-- begin: Description    -->
    <b-form-group :label="$t('GROUP.GENERAL.DESCRIPTION')">
      <b-form-textarea
        v-model="form.description"
        :state="validateState('description')"
      ></b-form-textarea>
    </b-form-group>
    <!-- end: Description    -->

    <b-form-group label="Disabled Late?">
      <b-form-checkbox
        inline
        v-model="$v.form.mode.$model"
        value="1"
        unchecked-value="0"
        >{{ $t("BUTTON.YES") }}</b-form-checkbox
      >
    </b-form-group>

    <b-button
      ref="submit"
      block
      type="submit"
      variant="primary"
      v-bind:class="{ 'spinner spinner-light spinner-right': isBusy === true }"
      >{{ $t("GROUP.GENERAL.FORM.SUBMIT") }}</b-button
    >
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "StaffGroupPageForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
        description: null,
        mode: 0,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      mode: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Staff Group Form submission");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const newForm = {
        name: this.form.name,
        description: this.form.description,
        senior_mode: this.form.mode,
        group_type_id: 2,
      };

      this.$store.dispatch("saveStaffGroup", { newForm }).then(() => {
        this.$emit("success", true);
        swal("Success!");
      });
    },
  },
  computed: {
    ...mapState({
      form: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupForm.form,
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupForm.isBusy,
    }),
  },
};
</script>

<style scoped></style>
