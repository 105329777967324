<template>
  <div>
    <b-table
      ref="employee-group-table"
      responsive
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      table-class="table-head-custom table-vertical-center"
      @row-clicked="showEditForm"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>
      <!-- end: t-row -->

      <template v-slot:cell(mode)="data">
        <span class="font-weight-bold">{{
          data.item.mode === false ? "No" : "Yes"
        }}</span>
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEditForm(data.item)"
            v-b-tooltip.hover="$t('GROUP.HOVER.GROUPEDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <!-- begin::: show-employee group btn        -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showStaffGroup(data.item)"
            v-b-tooltip.hover="$t('GROUP.HOVER.GROUPLIST')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </button>
          <!-- end::: show-employee group btn        -->

          <button
            class="btn btn-icon btn-light btn-hover-danger mr-3"
            v-on:click="deleteStaffGroup(data)"
            v-b-tooltip.hover="$t('GROUP.HOVER.GROUPDELETE')"
          >
            <span class="svg-icon svg-icon-md svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        @change="refresh"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>

    <StaffMembershipModal></StaffMembershipModal>
    <StaffGroupModal></StaffGroupModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StaffMembershipModal from "@/modules/school/components/staff/group/membership/StaffMembershipModal";
import StaffGroupModal from "@/modules/school/components/staff/group/StaffGroupModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "StaffGroupPageTable",
  components: { AppLoader, StaffGroupModal, StaffMembershipModal },
  props: ["reload"],

  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("GROUP.GENERAL.NAME"), sortable: true },
        { key: "description", label: this.$t("GROUP.GENERAL.DESCRIPTION") },
        { key: "mode", label: "Disabled Late" },
        { key: "actions", label: this.$t("GROUP.GENERAL.ACTIONS") },
      ],
      testItems: [
        {
          name: "Haha",
          description: "Haha",
        },
      ],
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    /**
     *
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("getStaffGroup")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response;
        })
        .finally(() => (this.isBusy = false));
    },

    refresh(page) {
      this.$store.commit("setStaffGroupPage", page);
      this.fetch();
    },

    /**
     *
     * @returns {Promise<any>}
     */
    getGroups() {
      return this.$store.dispatch("getStaffGroup").then((response) => {
        console.log("Received", response);
        // this.items = response.data.data;
        return response;
      });
    },

    /**
     * Show employee group.
     *
     * @param item
     */
    showStaffGroup(item) {
      console.log(item);
      const group = {
        id: item.id,
        name: item.name,
        description: item.description,
      };
      this.$store.dispatch("specifyGroup", group);
      this.$bvModal.show("group-membership-modal");
    },

    /**
     * Display the edit form to the user.
     *
     * @param item from row
     */
    showEditForm(item) {
      console.log(item);

      const group = {
        id: item.id,
        name: item.name,
        description: item.description,
        mode: item.mode,
      };
      this.$store.dispatch("specifyStaffGroupEdit", group);
      this.$bvModal.show("employee-group-edit-modal");
    },

    /**
     * Begin deleting employee group.
     *
     * Uses GROUP ID.
     *
     * @param data
     */
    deleteStaffGroup(data) {
      console.log(data);
      const groupName = data.item.name;

      this.$bvModal
        .msgBoxConfirm(groupName + " will be deleted", {
          title: this.$t("ALERT.SURE"),
        })
        .then((isOkay) => {
          if (isOkay) this.attemptDelete(data.item.id, groupName);
        });
    },

    /**
     * Attempts to delete group using GROUP ID
     *
     * @param groupId
     * @param name
     */
    attemptDelete(groupId, name) {
      console.log("Group id is: ", groupId);

      this.$store
        .dispatch("deleteStaffGroup", {
          id: groupId,
        })
        .then(() => {
          this.$bvModal.msgBoxOk(`Deleted ${name}!`);
          this.fetch();
        })
        .catch(() => this.$bvModal.msgBoxOk(`Failed to delete ${name}`));
    },
  },

  computed: {
    ...mapState({
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupList.isBusy,
    }),
    ...mapGetters({
      items: "getStaffGroup",
      pagination: "getStaffGroupPagination",
    }),
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.$store.dispatch("getStaffGroup");
    },
  },
};
</script>

<style scoped></style>
