<template>
  <!-- begin::App Page Card -->
  <b-card class="card-custom" :no-body="tabs">
    <template #header v-if="!tabs">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          <slot name="card-title"></slot>
        </h3>
      </div>

      <div class="card-toolbar">
        <slot name="card-toolbar"></slot>
      </div>
    </template>
    <slot></slot>
  </b-card>
  <!-- end::App Page Card-->
</template>

<script>
export default {
  name: "AppPageCard",
  props: { tabs: Boolean },
};
</script>

<style scoped></style>
