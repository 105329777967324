<template>
  <div class="d-flex flex-column justify-content-center mt-5">
    <div class="symbol symbol-150 symbol-light align-self-center">
      <span class="symbol-label rounded-circle">
        <span class="svg-icon svg-icon-9x">
          <inline-svg
            src="/media/svg/illustrations/null/empty_2.svg"
          ></inline-svg>
        </span>
      </span>
    </div>

    <div class="align-self-center">
      <h5 class="font-weight-bold mt-5 text-center">
        {{ textTop }}
      </h5>
      <p class="text-center text-muted text-h6">
        {{ textBottom }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppEmptyList",
  props: {
    textTop: {
      type: String,
      required: true,
    },
    textBottom: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
