<template>
  <div class="">
    <b-form>
      <!-- begin: Monday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.MONDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.monday.day_type"
            :options="dayTypes"
            :state="validateState('monday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.monday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.monday.time_in"
            :state="validateState('monday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.monday.day_type === 1"
        >
          <b-form-timepicker
            class=""
            v-model="form.monday.time_out"
            :state="validateState('monday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Monday -->

      <!-- begin: Tuesday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.TUESDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.tuesday.day_type"
            :options="dayTypes"
            :state="validateState('tuesday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.tuesday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.tuesday.time_in"
            :state="validateState('tuesday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.tuesday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.tuesday.time_out"
            :state="validateState('tuesday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Tuesday -->

      <!-- begin: Wednesday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.WEDNESDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.wednesday.day_type"
            :options="dayTypes"
            :state="validateState('wednesday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.wednesday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.wednesday.time_in"
            :state="validateState('wednesday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.wednesday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.wednesday.time_out"
            :state="validateState('wednesday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Wednesday -->

      <!-- begin: Thursday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.THURSDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.thursday.day_type"
            :options="dayTypes"
            :state="validateState('thursday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.thursday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.thursday.time_in"
            :state="validateState('thursday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.thursday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.thursday.time_out"
            :state="validateState('thursday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Thursday -->

      <!-- begin: Friday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.FRIDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.friday.day_type"
            :options="dayTypes"
            :state="validateState('friday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.friday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.friday.time_in"
            :state="validateState('friday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.friday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.friday.time_out"
            :state="validateState('friday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Friday -->

      <!-- begin: Saturday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.SATURDAY") }}
      </p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.saturday.day_type"
            :options="dayTypes"
            :state="validateState('saturday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.saturday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.saturday.time_in"
            :state="validateState('saturday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.saturday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.saturday.time_out"
            :state="validateState('saturday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Saturday -->

      <!-- begin: Sunday -->
      <p class="text-muted text-uppercase">
        {{ $t("GROUP.SCHEDULE.DAYS.SUNDAY") }}
      </p>

      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group :label="$t('GROUP.SCHEDULE.TYPE')" class="flex-fill">
          <b-form-select
            v-model="form.sunday.day_type"
            :options="dayTypes"
            :state="validateState('sunday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
          v-if="form.sunday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.sunday.time_in"
            :state="validateState('sunday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.END')"
          v-if="form.sunday.day_type === 1"
        >
          <b-form-timepicker
            v-model="form.sunday.time_out"
            :state="validateState('sunday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Sunday -->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { default as groupService } from "@/modules/school/services/group/emp_group.service";

export default {
  name: "StaffGroupScheduleForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        monday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        tuesday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        wednesday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        thursday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        friday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        saturday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        sunday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        id: null,
      },
      dayTypes: [
        { value: null, text: this.$t("GROUP.SCHEDULE.PROMPT_SELECT") },
        { value: 1, text: "Full Day" },
        { value: 3, text: "Off Day" },
      ],
    };
  },
  validations: {
    form: {
      monday: {
        day_type: {},
        time_in: {
          required: requiredIf(function (value) {
            console.log("Form is: ", value);
            console.log("This form is:", this);
            return value?.day_type === 1 ?? true;
          }),
        },
        time_out: {
          required: requiredIf(function (value) {
            return value?.day_type === 1 ?? true;
          }),
        },
      },
      tuesday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
      wednesday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
      thursday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
      friday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
      saturday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
      sunday: {
        day_type: {},
        time_in: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
        time_out: {
          required: requiredIf((value) => value?.day_type === 1 ?? true),
        },
      },
    },
  },

  mounted() {
    this.form.id = this.getStaffGroupInformation.id;
    this.$store.dispatch("fetchStaffGroupSchedule");
  },

  methods: {
    validateState(name) {
      console.log(name);
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    save() {
      console.log("Staff Group Form submission", this.$v.form);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch("saveStaffGroupSchedule", this.form).then(() => {
        this.$emit("success", true);
        this.$bvModal.msgBoxOk("Schedule has been modified.", {
          title: "Schedule saved",
        });
      });
    },
  },
  computed: {
    ...mapGetters([
      "checkStaffGroupScheduleEvent",
      "getStaffGroupInformation",
      "getStaffGroupSchedule",
      "getStaffGroupScheduleForm",
    ]),
    ...mapGetters({
      isCleared: "getStaffGroupIsCleared",
    }),
  },
  watch: {
    /**
     * Check Schedule event to trigger the actual save action.
     */
    checkStaffGroupScheduleEvent(newState, oldState) {
      console.log(newState, oldState);
      this.save();
    },

    getStaffGroupSchedule(newSchedule) {
      console.log("Scheduled changed", newSchedule);
    },

    /**
     * Observe changes in group schedule state.
     *
     * Assigned API fetched form if it's not null.
     *
     * @param form
     */
    getStaffGroupScheduleForm(form) {
      console.log("Form changed", form);
      console.log("Existing form", this.form);

      if (form !== null) {
        console.log("Changing form.");
        this.form = form;
      }
    },

    /**
     * Check for clearance.
     *
     * @param newState
     */
    isCleared(newState) {
      if (newState) {
        console.log("Test empty form");
        this.form = groupService.generateEmptyForm();
        this.$store.dispatch("revertStaffGroupScheduleList");
      }
    },
  },
};
</script>

<style scoped></style>
