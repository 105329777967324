<template>
  <div class="d-flex flex-column">
    <StaffGroupScheduleControl></StaffGroupScheduleControl>
    <StaffGroupScheduleForm></StaffGroupScheduleForm>
  </div>
</template>

<script>
import StaffGroupScheduleForm from "@/modules/school/components/staff/group/schedule/StaffGroupScheduleForm";
import StaffGroupScheduleControl from "@/modules/school/components/staff/group/schedule/StaffGroupScheduleControl";
export default {
  name: "StaffGroupSchedule",
  components: { StaffGroupScheduleControl, StaffGroupScheduleForm },
};
</script>

<style scoped></style>
