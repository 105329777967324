<template>
  <AppPageCard>
    <template #card-title>
      Staff Group
    </template>

    <template #card-toolbar>
      <!-- begin:: toolbar      -->
      <div>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
          v-b-tooltip.hover="$t('GROUP.HOVER.REFRESH')"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="primary"
          class="text-white"
          v-b-toggle.employee-group-sidebar
        >
          {{ $t("GROUP.CREATE") }}
        </b-button>
      </div>
      <!-- end:: toolbar      -->
    </template>

    <!-- begin:: sidebar      -->
    <b-sidebar
      ref="employee-group-sidebar-ref"
      id="employee-group-sidebar"
      backdrop
      right
      lazy
      :title="$t('GROUP.CREATEGROUP')"
      sidebar-class="offcanvas"
      header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
      bg-variant="white"
    >
      <template v-slot:header-close>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </template>
      <div class="px-10 mt-5 mb-5 pb-5">
        <StaffGroupPageForm v-on:success="closeForm"></StaffGroupPageForm>
      </div>
    </b-sidebar>

    <!-- end:: sidebar      -->
    <StaffGroupPageTable :reload="reload"></StaffGroupPageTable>
  </AppPageCard>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import StaffGroupPageTable from "@/modules/school/components/staff/group/StaffGroupPageTable";
import StaffGroupPageForm from "@/modules/school/components/staff/group/StaffGroupPageForm";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "StaffGroupPage",
  data: function () {
    return {
      reload: false,
    };
  },
  components: { AppPageCard, StaffGroupPageForm, StaffGroupPageTable },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.GROUP"), route: "/school/staff/group" },
    ]);
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
    closeForm() {
      const sideBar = this.$refs["employee-group-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
