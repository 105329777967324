<template>
 <span class="btn btn-light-primary" v-on:click="$emit('button-clicked')"
 ><span class="svg-icon svg-icon-2x svg-icon-light-primary">
      <inline-svg src="/media/svg/icons/Media/Repeat.svg" /> </span
 >{{ $t("GROUP.SCHEDULE.CLEAR") }}
  </span>
</template>

<script>
export default {
  name: "StaffGroupSchedulePurgeButton",
  methods: {},
};
</script>

<style scoped></style>
