<template>
  <div class="d-flex justify-content-center my-5">
    <b-spinner
      variant="primary"
      class="justify-content-center"
      type="grow"
    ></b-spinner>
    <span class="ml-5 h4 text-secondary font-weight-bold">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>

<style scoped></style>
