<template>
  <div class="d-flex justify-content-end">
    <StaffGroupSchedulePurgeButton
      @button-clicked="deleteSchedule"
    ></StaffGroupSchedulePurgeButton>
  </div>
</template>

<script>
import StaffGroupSchedulePurgeButton from "@/modules/school/components/staff/group/schedule/StaffGroupSchedulePurgeButton";
export default {
  name: "StaffGroupScheduleControl",
  components: { StaffGroupSchedulePurgeButton },
  methods: {
    /**
     * When child emitted on click, calls store to delete group schedule.
     */
    deleteSchedule() {
      const groupId = this.$store.getters.getSelectedStaffGroup.id;
      console.log(groupId);

      this.$bvModal
        .msgBoxConfirm(this.$t("GROUP.SCHEDULE.DELETECONFIRM"))
        .then((isOk) => {
          console.log(isOk);

          if (isOk) this.attempt(groupId);
          else
            this.$bvToast.toast(this.$t("GROUP.SCHEDULE.DELETE_CANCELLED"), {
              variant: "primary",
            });
        });
    },

    attempt(groupId) {
      this.$store
        .dispatch("deleteStaffGroupSchedule", {
          id: groupId,
        })
        .then(() => {
          this.$bvModal.msgBoxOk("Schedule is deleted.");
          this.$store.dispatch("clearStaffGroupScheduleList");
        })
        .catch(() => this.$bvModal.msgBoxOk("Cannot delete Schedule."));
    },
  },
};
</script>

<style scoped></style>
