<template>
  <div class="d-flex flex-row align-content-center p-2">
    <b-avatar
      class="mr-5"
      size="4.5rem"
      v-if="item.image_url"
      variant="light"
      :src="item.image_url"
    >
    </b-avatar>
    <div
      class="symbol symbol-60 symbol-circle symbol-light-primary mr-5"
      v-else-if="!item.image_url"
    >
      <span class="symbol-label font-size-h3">{{
        item.name !== "" ? item.name.charAt(0) : "A"
      }}</span>
    </div>
    <div class="d-flex flex-column">
      <span class="font-weight-bolder">
        {{ item.name }}
      </span>
      <span class="font-weight-bold">
        {{ item.code }}
      </span>
      <span class="font-weight-bold">
        {{ item.email }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonEmployeeSummary",
  props: {
    item: Object,
  },
};
</script>

<style scoped></style>
