<template>
  <b-modal
    id="employee-group-edit-modal"
    footer-bg-variant="light"
    :title="groupName"
    :size="size"
  >
    <b-tabs
      pills
      nav-class="nav-primary"
      v-model="tab"
      @activate-tab="changeModalSize"
    >
      <b-tab :title="this.$t('GROUP.SCHEDULE.TABGENERAL')" lazy>
        <StaffGroupEditForm class="m-2 mt-3 p-2"></StaffGroupEditForm>
      </b-tab>

      <b-tab :title="this.$t('GROUP.SCHEDULE.BASE')" lazy>
        <StaffGroupSchedule class="m-2 mt-3 p-2"></StaffGroupSchedule>
      </b-tab>
    </b-tabs>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="light" class="btn-hover-danger" @click="cancel"
        >{{ $t("BUTTON.CANCEL") }}
      </b-button>

      <b-button
        variant="primary"
        v-if="tab === 0"
        v-bind:class="{
          'spinner spinner-light spinner-right': getHeldStaffEditGroup,
        }"
        v-on:click="updateGroup"
      >
        {{ $t("GROUP.UPDATE") }}
      </b-button>

      <b-button
        variant="primary"
        v-if="tab === 1"
        v-bind:class="{
          'spinner spinner-light spinner-right': getHeldStaffGroupSchedule,
        }"
        v-on:click="saveSchedule"
        >{{ $t("GROUP.SAVE") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import StaffGroupEditForm from "@/modules/school/components/staff/group/StaffGroupEditForm";
import { mapGetters } from "vuex";
import StaffGroupSchedule from "@/modules/school/components/staff/group/schedule/StaffGroupSchedule";

export default {
  name: "StaffGroupModal",
  components: {
    StaffGroupSchedule,
    StaffGroupEditForm,
  },
  data: function () {
    return {
      size: "md",
      isBusy: false,
      tab: 0,
    };
  },
  methods: {
    saveSchedule() {
      console.log("Emitting schedule save event..");
      this.$store.dispatch("engageStaffGroupFormSubmission");
    },
    changeModalSize(newTabIndex) {
      console.log(newTabIndex);
      switch (newTabIndex) {
        case 0:
          this.size = "md";
          break;
        case 1:
          this.size = "xl";
          break;
        default:
          break;
      }
    },
    updateGroup() {
      console.log("Emitting staff group update event..");
      this.$store.dispatch("engageUpdateStaffGroupForm");
    },
  },
  computed: {
    groupName() {
      return this.$store.getters.getStaffGroupInformation.name + " Group";
    },
    ...mapGetters([
      "getStaffGroupInformation",
      "getHeldStaffGroupSchedule",
      "getHeldStaffEditGroup",
    ]),
    ...mapGetters({
      editIsBusy: "getHeldStaffEditGroup",
      scheduleIsBusy: "getHeldStaffGroupSchedule",
    }),
  },
};
</script>

<style scoped></style>
